import React from 'react'
import './Portfolio.css'
import IMG1 from '../../assets/discordbot.png'
import IMG2 from '../../assets/arduino.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Discord Bot',
    github: 'https://github.com/wtfolol/PMA-bot',
    demo: 'https://dribble.com',
  },
  {
    id: 2,
    image: IMG2,
    title: 'Contactless temperature Detactor',
    github: 'https://github.com/wtfolol/ContactlessTemperatureDetector',
    demo: 'https://dribble.com',
  },
]

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, github, demo }) => {
          return (
            <article key={id} className="portfollio__item">
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <div className="portfolio__item-cta">
                <a href={github} className="btn" target="_blank">
                  Github
                </a>
                {/* <a href={demo} className="btn btn-primary" target="_blank">
                  Live demo
                </a> */}
              </div>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Portfolio
