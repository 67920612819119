import React from 'react'
import './Footer.css'
import { FaFacebook } from 'react-icons/fa'
import { FiLinkedin } from 'react-icons/fi'
import { IoLogoTwitter} from 'react-icons/io'


const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo"></a>

      <ul className="permalinks">
        <li>
          <a href="#"></a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a href="https://facebook.com"><FaFacebook/></a>
        <a href="https://linkedin.com"><FiLinkedin/></a>
        <a href="https://twitter"><IoLogoTwitter/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Lim Wu Shen. All rights reserved.</small>
      </div>
    </footer>
  )
}

export default Footer
