import React from 'react'
import './Services.css'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>FileMaker App Design</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Custom filemaker solution development based on requirement.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>UI/UX design for filemaker solution.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Refine database design.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Update and support on old filemaker solution.</p>
            </li>
          </ul>
        </article>

        <article className="service">
          <div className="service__head">
            <h3>Web Hosting Service</h3>
          </div>

          <ul className="service__list">
            <li>
              <BiCheck className="service__list-icon" />
              <p>Wordpress web hosting.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>UI/UX design for static website.</p>
            </li>
            <li>
              <BiCheck className="service__list-icon" />
              <p>Domain email setup.</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default Services
