import React from 'react'
import { BsLinkedin } from 'react-icons/bs'
import { FaGithub } from 'react-icons/fa'
import { AiFillFacebook } from 'react-icons/ai'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/lim-wu-shen-aa1352140/" target="_blank">
        <BsLinkedin />
      </a>
      <a href="https://github.com/wtfolol" target="_blank">
        <FaGithub />
      </a>
      <a href="https://www.facebook.com/iamJustWS/" target="_blank">
        <AiFillFacebook />
      </a>
    </div>
  )
}

export default HeaderSocials
