import {React, useState} from 'react'
import './Contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'
import { useRef } from 'react'
import emailjs from 'emailjs-com'
import ReCAPTCHA from 'react-google-recaptcha'

const Contact = () => {
  const form = useRef()
  const [captchavalue, setcaptchavalue] = useState(false)

  function onChange(value) {
    console.log('Captcha value:', value)
    setcaptchavalue(true)
  }

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_5t87xc3',
        'template_47gt82o',
        form.current,
        'LyOssygRsr_B09TAi'
      )
      .then(
        (result) => {
          console.log(result.text)
          e.target.reset()
          alert('Your form successfully submitted!')
        },
        (error) => {
          console.log(error.text)
        }
      )
  }

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className="contact__option-icon" />
            <h4>Email</h4>
            <h5>ws.work.11@gmail.com</h5>
            <a href="mailto:ws.work.11@gmail.com" target="_blank">
              Send an e-mail
            </a>
          </article>

          <article className="contact__option">
            <RiMessengerLine className="contact__option-icon" />
            <h4>Messenger</h4>
            <h5>Lim Wu Shen</h5>
            <a href="https://m.me/iamJustWS" target="_blank">
              Send a message
            </a>
          </article>

          <article className="contact__option">
            <BsWhatsapp className="contact__option-icon" />
            <h4>Whatsapp</h4>
            <a
              href="https://api.whatsapp.com/send?phone=+60167179073"
              target="_blank"
            >
              Send a message
            </a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          />
          <>
            {captchavalue ? (
              <button type="submit" className="btn btn-primary">
                Send Messsage
              </button>
            ) : (
              <ReCAPTCHA
                sitekey="6LcEOgofAAAAAJGpAY_qn-8nIxY2qc5xoJx0p9rx"
                onChange={onChange}
              />
            )}
          </>
        </form>
      </div>
    </section>
  )
}

export default Contact
